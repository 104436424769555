/* 404 */

.page-404 {
  min-height: 100vh;
  padding: 108px 160px 33vw 160px;
  text-align: center;
  background: url(../images/404/rocket-ds.svg), url(../images/404/drops-1.svg),
    linear-gradient(90deg, #01397a 0%, #0163ad 100%);
  background-size: 100% auto, cover, cover;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: bottom 0 center, center, center;
}

.page-404 h1 {
  margin: 0 0 10px 0;
  font-weight: bold;
  font-size: 120px;
  line-height: 146px;
  text-align: center;
  color: #ffffff;
}

.page-404 p {
  margin: 0 0 30px 0;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.back-home-btn {
  display: inline-block;
  padding: 20px 40px;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #f9ae3b;
  border-radius: 8px;
}

@media (max-width: 767px) {
  .page-404 {
    min-height: auto;
    padding: 165px 24px 300px;
    background: url(../images/404/rocket-ph.svg), url(../images/404/drops-1.svg),
      linear-gradient(90deg, #01397a 0%, #0163ad 100%);
    background-size: 768px auto, cover, cover;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: top 400px center, center, center;
  }

  .page-404__content {
    max-width: 270px;
    margin: 0 auto;
  }

  .page-404 p {
    margin: 0 0 68px 0;
  }
}
